<template>
    <div class="richBox">
        <div class="richText" v-html="richText"></div>
    </div>
</template>

<script>
export default {
    data() {
        return {
            richText: ''
        }
    },
    watch: {
        $route(to) {
            if (to.query) {
                console.log(to.query)
                this.richText = to.richText

            }
        },

    },
    async created() {
        if (this.$route.query) {
            console.log(this.$route.query)
            this.richText = this.$route.query.richText

        }
    },
    methods: {

    },
}
</script>

<style lang="less" scoped>
.richBox {
    width: 100%;
    max-width: 1200px;
    margin: auto;
}
</style>
