var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      staticClass: "searchList d-flex flex-column",
      attrs: { id: "searchList", name: "searchList" },
    },
    [
      _c("div", { staticClass: "bodyl" }),
      _c("div", { staticClass: "bodyr" }),
      _c(
        "ul",
        { staticClass: "tabBox d-flex a-center" },
        _vm._l(_vm.tabList, function (item, index) {
          return _c(
            "li",
            {
              key: item.value,
              on: {
                click: function ($event) {
                  return _vm.tabHandle(item, index)
                },
              },
            },
            [
              _c("span", { class: { active: _vm.tabIndex == index } }, [
                _vm._v(_vm._s(item.label)),
              ]),
            ]
          )
        }),
        0
      ),
      _vm.tabIndex == 0
        ? _c("CardList", {
            attrs: { cardData: _vm.roomList, isCardTit: _vm.isCardTit },
          })
        : _c("CardList", {
            attrs: {
              titleImg: "3",
              cardData: _vm.liveList,
              isCardTit: _vm.isCardTit,
            },
          }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }