var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _vm._m(0)
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "ptxt" }, [
      _c("h1", { staticClass: "title" }, [_vm._v("关于我们")]),
      _c("p", [
        _vm._v(
          "广西牧纯科技有限公司旗下的随心语音是一家致力于为所有人带来欢乐的语音直播APP以及网站。随心语音于2023年1月1日起正式运营，力图在语音直播行业稳步提高，做到最好。"
        ),
      ]),
      _c("p", [
        _vm._v(
          "目前，随心语音以游戏开黑语音直播为主，涵盖了点唱、电台、交友等多种语音直播内容。每天，都有成千上万的用户与小队长在随心语音分享游戏和欢笑，获得属于自己独一份的快乐。"
        ),
      ]),
      _c("p", [
        _vm._v(
          "随心语音欢迎每一名用户：无论是组织还是个人，无论是小队长还是用户，我们都力图为您提供最好的语音直播以及交友环境；并希望与您携手，共同进步，绘制更加美好的未来。"
        ),
      ]),
      _c("p", [_vm._v("随声所至，随心而往！")]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }