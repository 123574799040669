<template>
    <div class="ptxt">
        <h1 class="title">关于我们</h1>

        <p>广西牧纯科技有限公司旗下的随心语音是一家致力于为所有人带来欢乐的语音直播APP以及网站。随心语音于2023年1月1日起正式运营，力图在语音直播行业稳步提高，做到最好。</p>

        <p>目前，随心语音以游戏开黑语音直播为主，涵盖了点唱、电台、交友等多种语音直播内容。每天，都有成千上万的用户与小队长在随心语音分享游戏和欢笑，获得属于自己独一份的快乐。</p>

        <p>随心语音欢迎每一名用户：无论是组织还是个人，无论是小队长还是用户，我们都力图为您提供最好的语音直播以及交友环境；并希望与您携手，共同进步，绘制更加美好的未来。</p>

        <p>随声所至，随心而往！</p>

    </div>
</template>

<script>
export default {
    data() {
        return {

        }
    },

}
</script>

<style lang="less" scoped>
.ptxt {
    width: 800px;
    color: #fff;
    padding: 50px 0;
    margin: 0 auto;
    box-sizing: border-box;
    min-height: 80vh;

    .title {
        margin-bottom: 50px;
    }

    p {
        margin-bottom: 40px;
    }
}
</style>
