<template>
	<div class="god d-flex flex-column" id="god" name="god">
		<!-- 左右发光图 -->
		<div class="bodyl"></div>
		<div class="bodyr"></div>
		<ul class="godTop d-flex a-center j-center">
			<li v-for="(item, index) in tabList" :key="item.value" @click="tabHandle(item, index)"
				@mouseenter="mouseenterHandle(index)" @mouseleave="mouseleaveHandle()">
				<img class="icon" :src="tabIndex == index || activeIndex == index ? item.iconActive : item.icon" alt="">
				<span :class="{ active: tabIndex == index || activeIndex == index }">{{ item.label }}</span>
			</li>
		</ul>
		<!-- 列表 -->
		<CardList v-show="!isLoading || playList.length > 0" titleImg="3" :cardData="playList" :isCardTit="isCardTit" />
	</div>
</template>

<script>
import CardList from '@/components/CardList.vue'
import {
	getAllLiveList,
	getSuggestLiveList,
	getClassSkillList,
	getSkillLiveList,
} from "@/util/api/api.js"

export default {
	components: {
		CardList
	},
	data() {
		return {
			isLoading: true,
			isCardTit: false,
			iconList: [
				{
					id: 'allPlay',
					icon: require('@/assets/imgs/god/icon1.png'),
					iconActive: require('@/assets/imgs/god/icon11.png'),
					label: '全部',
				},
				{
					id: "suggest",
					icon: require('@/assets/imgs/god/icon2.png'),
					iconActive: require('@/assets/imgs/god/icon22.png'),
					label: '推荐',
				},
				{
					id: 1,
					icon: require('@/assets/imgs/god/icon3.png'),
					iconActive: require('@/assets/imgs/god/icon33.png'),
					label: '手游',
				},
				{
					id: 2,
					icon: require('@/assets/imgs/god/icon4.png'),
					iconActive: require('@/assets/imgs/god/icon44.png'),
					label: '端游',
				},
				{
					id: 3,
					icon: require('@/assets/imgs/god/icon5.png'),
					iconActive: require('@/assets/imgs/god/icon55.png'),
					label: '电台',
				},
				{
					id: 4,
					icon: require('@/assets/imgs/god/icon6.png'),
					iconActive: require('@/assets/imgs/god/icon66.png'),
					label: '音色',
				},
			],
			tabList: [
				{
					id: 'allPlay',
					icon: require('@/assets/imgs/god/icon1.png'),
					iconActive: require('@/assets/imgs/god/icon11.png'),
					label: '全部',
				}, {
					id: "suggest",
					icon: require('@/assets/imgs/god/icon2.png'),
					iconActive: require('@/assets/imgs/god/icon22.png'),
					label: '推荐',
				}
			],
			tabIndex: 0,
			playList: [],
			activeIndex: null,
			paginationProps: {
				pageNum: 1,
				pageSize: 20,
				total: 0
			},
		}

	},
	async created() {
		await this._getClassSkillList()
		if (this.$route.query && this.$route.query.type) {
			let type = this.$route.query.type
			let index = this.tabList.findIndex(item => item.id == type)
			// 首页大神推荐分类进入，默认是推荐
			index = index >= 0 ? index : 1
			this.tabHandle(this.tabList[index], index)
		} else {
			// 其他方式进来默认全部
			this.tabHandle(this.tabList[0], 0)
		}
	},
	mounted() {
		// 开启触底监听
		addEventListener('scroll', this.getscroll)
	},
	methods: {
		// 获取全部大神列表
		async _getAllLiveList() {
			try {
				let params = {
					pageNum: this.paginationProps.pageNum,
					pageSize: this.paginationProps.pageSize,
				}
				let res = await getAllLiveList(params)
				if (res.data && res.data.list.length >= 0) {
					if (params.pageNum == 1) {
						this.playList = res.data.list
					} else {
						this.playList = this.playList.concat(res.data.list)
					}
					this.isLoading = false
					this.paginationProps.total = res.data.total
				}
				this.isLoading = false
			} catch (error) {
				this.isLoading = false
				console.log(error)
			}
		},
		// 获取大神推荐列表
		async _getSuggestLiveList() {
			try {
				let params = {
					pageNum: this.paginationProps.pageNum,
					pageSize: this.paginationProps.pageSize,
				}
				let res = await getSuggestLiveList(params)
				console.log(res)
				if (res.data && res.data.list.length >= 0) {
					if (params.pageNum == 1) {
						this.playList = res.data.list
					} else {
						this.playList = this.playList.concat(res.data.list)
					}
					this.isLoading = false
					this.paginationProps.total = res.data.total
				}
				this.isLoading = false
			} catch (error) {
				this.isLoading = false
				console.log(error)
			}
		},
		// 获取大神技能分类列表
		async _getClassSkillList() {
			try {
				let res = await getClassSkillList()
				if (res.data && res.data.list.length >= 0) {
					res.data.list.forEach(item => {
						let index = this.iconList.findIndex(i => i.id == item.id)
						if (index >= 0) {
							item.icon = this.iconList[index].icon
							item.iconActive = this.iconList[index].iconActive
						}
						item.label = item.name
					})
					this.tabList = this.tabList.concat(res.data.list)
					console.log(this.tabList)
					this.isLoading = false
				}
				this.isLoading = false
			} catch (error) {
				this.isLoading = false
				console.log(error)
			}
		},
		// 获取大神技能分类对应的大神列表
		async _getSkillLiveList(id) {
			try {
				let params = {
					id: id,
					pageNum: this.paginationProps.pageNum,
					pageSize: this.paginationProps.pageSize,
				}
				let res = await getSkillLiveList(params)
				if (res.data && res.data.list.length >= 0) {
					if (params.pageNum == 1) {
						this.playList = res.data.list
					} else {
						this.playList = this.playList.concat(res.data.list)
					}
					this.isLoading = false
					this.paginationProps.total = res.data.total
				}
				this.isLoading = false
			} catch (error) {
				this.isLoading = false
				console.log(error)
			}
		},

		// tab切换事件
		tabHandle(item, index) {
			this.tabIndex = index
			console.log("item", item);
			if (item.id == 'allPlay') {
				this.paginationProps.pageNum = 1
				this._getAllLiveList()
			} else if (item.id == 'suggest') {
				this.paginationProps.pageNum = 1
				this._getSuggestLiveList()
			} else {
				this.paginationProps.pageNum = 1
				this._getSkillLiveList(item.id)
			}
		},

		mouseenterHandle(index) {
			this.activeIndex = index
		},

		mouseleaveHandle() {
			this.activeIndex = null
		},

		// 判断滚动是否触底
		async getscroll() {
			// 接口未返回，不重新触发
			if (this.isLoading) {
				return
			}
			// 整个文档的高度
			let scrollHeight = document.documentElement.scrollHeight
			// 可见区的高度
			let clientHeight = document.documentElement.clientHeight
			// 滚动的高度
			let scrollTop = document.documentElement.scrollTop
			if ((scrollTop + clientHeight + 10) >= scrollHeight) {
				console.log('滚动触底')
				if (this.playList.length < this.paginationProps.total) {
					this.paginationProps.pageNum++
					let id = this.tabList[this.tabIndex].id
					this.isLoading = true
					if (id == 'allPlay') {
						await this._getAllLiveList()
					} else if (id == 'suggest') {
						await this._getSuggestLiveList()
					} else {
						await this._getSkillLiveList(id)
					}
					return
				}
				console.log('没有更多了')
			}
		}
	},
	destroyed() {
		// 移除触底监听
		removeEventListener('scroll', this.getscroll)
	},
}
</script>

<style lang="less" scoped>
.god {
	padding-bottom: 20px;
	min-height: 760px;
	position: relative;
	overflow: hidden;

	.bodyl {
		width: 419px;
		height: 1161px;
		position: absolute;
		top: 0px;
		left: 0;
		background: url(../assets/imgs/l.png) no-repeat;
	}

	.bodyr {
		width: 493px;
		height: 858px;
		position: absolute;
		top: 0px;
		right: 0;
		background: url(../assets/imgs/bodyr.png) no-repeat;
	}

	.godTop {
		width: 1200px;
		height: 114px;
		background: #292130;
		border-radius: 12px;
		margin: 24px auto 32px;
		// padding: 23px 0 0 168px;
		overflow: hidden;
		position: relative;
		z-index: 9;
		box-sizing: border-box;

		li {
			float: left;
			margin: 0 50px;
			cursor: pointer;
			margin-top: 4px;

			.icon {
				width: 48px;
				height: 48px;
				display: block;
			}

			span {
				display: block;
				width: 48px;
				color: #69636e;
				font-size: 14px;
				text-align: center;
				margin-top: 12px;

				&.active {
					color: #B586F2;
				}
			}
		}
	}

}
</style>
<style lang="less">
.cardBox {
	.noData {
		.iconNoData {
			width: 162px !important;
		}
	}
}
</style>
