var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      staticClass: "god d-flex flex-column",
      attrs: { id: "god", name: "god" },
    },
    [
      _c("div", { staticClass: "bodyl" }),
      _c("div", { staticClass: "bodyr" }),
      _c(
        "ul",
        { staticClass: "godTop d-flex a-center j-center" },
        _vm._l(_vm.tabList, function (item, index) {
          return _c(
            "li",
            {
              key: item.value,
              on: {
                click: function ($event) {
                  return _vm.tabHandle(item, index)
                },
                mouseenter: function ($event) {
                  return _vm.mouseenterHandle(index)
                },
                mouseleave: function ($event) {
                  return _vm.mouseleaveHandle()
                },
              },
            },
            [
              _c("img", {
                staticClass: "icon",
                attrs: {
                  src:
                    _vm.tabIndex == index || _vm.activeIndex == index
                      ? item.iconActive
                      : item.icon,
                  alt: "",
                },
              }),
              _c(
                "span",
                {
                  class: {
                    active: _vm.tabIndex == index || _vm.activeIndex == index,
                  },
                },
                [_vm._v(_vm._s(item.label))]
              ),
            ]
          )
        }),
        0
      ),
      _c("CardList", {
        directives: [
          {
            name: "show",
            rawName: "v-show",
            value: !_vm.isLoading || _vm.playList.length > 0,
            expression: "!isLoading || playList.length > 0",
          },
        ],
        attrs: {
          titleImg: "3",
          cardData: _vm.playList,
          isCardTit: _vm.isCardTit,
        },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }