<template>
    <div class="searchList d-flex flex-column" id="searchList" name="searchList">
        <!-- 左右发光图 -->
        <div class="bodyl"></div>
        <div class="bodyr"></div>
        <ul class="tabBox d-flex a-center">
            <li v-for="(item, index) in tabList" :key="item.value" @click="tabHandle(item, index)">
                <span :class="{ active: tabIndex == index }">{{ item.label }}</span>
            </li>
        </ul>
        <!-- 列表 -->
        <CardList v-if="tabIndex == 0" :cardData="roomList" :isCardTit="isCardTit" />
        <CardList v-else titleImg="3" :cardData="liveList" :isCardTit="isCardTit" />
    </div>
</template>

<script>
import CardList from '@/components/CardList.vue'
import {
    search
} from "@/util/api/api.js"

export default {
    components: {
        CardList
    },
    data() {
        return {
            isCardTit: false,
            tabList: [
                {
                    id: 'room',
                    label: '相关房间',
                }, {
                    id: "user",
                    label: '相关用户',
                }
            ],
            tabIndex: 0,
            liveList: [],// 用户列表
            roomList: [],// 房间列表
            searchValue: '',
        }

    },
    watch: {
        async $route(to) {
            if (to.query && to.query.value) {
                await this._search(to.query.value)
            }
        }
    },
    async created() {
        if (this.$route.query && this.$route.query.value) {
            this.searchValue = this.$route.query.value
        }
        await this._search(this.searchValue)
    },
    methods: {
        // 获取全部大神列表
        async _search(keyWord) {
            try {
                let res = await search({ keyWord })
                if (res.data) {
                    this.liveList = res.data.liveList || []
                    this.roomList = res.data.roomList || []
                }
            } catch (error) {
                console.log(error)
            }
        },

        // tab切换事件
        tabHandle(item, index) {
            this.tabIndex = index
            console.log("item", item);
        },
    },
}
</script>

<style lang="less" scoped>
.searchList {
    padding-bottom: 20px;
    min-height: 760px;
    position: relative;
    overflow: hidden;

    .bodyl {
        width: 419px;
        height: 1161px;
        position: absolute;
        top: 0px;
        left: 0;
        background: url(../assets/imgs/l.png) no-repeat;
    }

    .bodyr {
        width: 493px;
        height: 858px;
        position: absolute;
        top: 0px;
        right: 0;
        background: url(../assets/imgs/bodyr.png) no-repeat;
    }

    .tabBox {
        width: 1200px;
        border-radius: 12px;
        margin: 24px auto 42px;
        position: relative;
        z-index: 9;
        box-sizing: border-box;

        li {
            cursor: pointer;
            margin-top: 4px;

            span {
                display: block;
                font-size: 30px;
                font-family: PingFangSC-Regular, PingFang SC;
                font-weight: 400;
                color: #908D92;
                margin-right: 96px;
                text-align: center;

                &:hover {
                    color: #FFFFFF;
                }

                &.active {
                    color: #FFFFFF;
                    position: relative;

                    &::after {
                        content: '';
                        position: absolute;
                        left: 0;
                        bottom: -8px;
                        width: 120px;
                        height: 2px;
                        background: #B586F2;
                        border-radius: 2px;
                    }
                }
            }
        }
    }

}
</style>
<style lang="less">
.cardBox {
    .noData {
        .iconNoData {
            width: 162px !important;
        }
    }
}
</style>
